import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-assigned-to-me" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tabs_nav = _resolveComponent("tm-tabs-nav")!
  const _component_tm_expansion_section = _resolveComponent("tm-expansion-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_expansion_section, {
      label: "Assigned to me",
      "label-bold": "",
      "no-padding": "",
      "border-on-collapse": "",
      expanded: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_tabs_nav, {
          modelValue: _ctx.tabValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabValue) = $event)),
          tabs: _ctx.tabs,
          "no-border": "",
          class: "mb-3",
          "tab-style": "pillsOutlined",
          "use-router": "",
          "show-counter-zero": ""
        }, null, 8, ["modelValue", "tabs"]),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ]))
}