
import { defineComponent } from 'vue'
import TmExpansionSection from '@/components/shared/tmExpansion/TmExpansionSection.vue'
import HomeOverviewBlocks from '@/components/pages/home/HomeOverviewBlocks.vue'
import HomeOverviewFilters from '@/components/pages/home/HomeOverviewFilters.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    HomeOverviewFilters,
    HomeOverviewBlocks,
    TmExpansionSection,
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    return {
      isSmMax,
    }
  },
})
