
import { defineComponent, ref } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import HomeHero from '@/components/pages/home/HomeHero.vue'
import HomePinnedViews from '@/components/pages/home/sidebar/HomePinnedViews.vue'
import HomeOverview from '@/components/pages/home/HomeOverview.vue'
import HomeRecentlyViewed from '@/components/pages/home/sidebar/HomeRecentlyViewed.vue'
import HomeAssignedToMe from '@/components/pages/home/HomeAssignedToMe.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    HomeAssignedToMe,
    HomeOverview,
    HomeRecentlyViewed,
    HomePinnedViews,
    PageBlock,
    HomeHero,
  },
  setup() {
    const { isMdMax } = useBreakpoints()
    const tabs = [
      {
        name: 'dashboard',
        label: 'Dashboard',
      },
      {
        name: 'views',
        label: 'Views',
      },
    ]
    const currentTab = ref('dashboard')

    return {
      tabs,
      isMdMax,
      currentTab,
    }
  },
})
