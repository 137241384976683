import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-430fb402"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-expansion-section__header" }
const _hoisted_2 = { class: "flex-grow-1 relative" }
const _hoisted_3 = { class: "tm-expansion-section__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_expansion = _resolveComponent("tm-expansion")!

  return (_openBlock(), _createBlock(_component_tm_expansion, {
    modelValue: _ctx.state,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state) = $event)),
    class: _normalizeClass(["tm-expansion-section", {
      'tm-expansion-section--border-on-collapse': _ctx.borderOnCollapse,
      'tm-expansion-section--no-border': _ctx.noBorder,
      'tm-expansion-section--no-padding': _ctx.noPadding,
    }]),
    "hide-border": "",
    expanded: _ctx.expanded
  }, {
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header", {}, () => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.draggable)
              ? (_openBlock(), _createBlock(_component_tm_icon, {
                  key: 0,
                  name: "drag_indicator",
                  class: "tm-expansion-section__draggable"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["tm-expansion-section__label", {
                'tm-expansion-section__label--bold': _ctx.labelBold,
              }])
            }, [
              _renderSlot(_ctx.$slots, "label", { label: _ctx.label }, () => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ], true)
            ], 2)
          ])
        ]),
        (_ctx.$slots['header-right'] && _ctx.state)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "tm-expansion-section__header-right",
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, [
              _renderSlot(_ctx.$slots, "header-right", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ], true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["modelValue", "class", "expanded"]))
}