
import { defineComponent, ref, computed } from 'vue'
import TmExpansionSection from '@/components/shared/tmExpansion/TmExpansionSection.vue'
import TmTabsNav from '@/components/shared/TmTabsNav.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmTabsNav,
    TmExpansionSection,
  },
  setup() {
    const tabValue = ref()
    const { isEmptyMode } = useModes()

    const tabs = ref([
      {
        name: 'Chats',
        unread: computed(() => !isEmptyMode.value),
        icon: 'chat',
        counter: computed(() => isEmptyMode.value ? 0 : 23),
        to: { name: 'base.home.overview' },
      },
      {
        name: 'Tasks',
        icon: 'tmi-layers-tasks',
        counter: computed(() => isEmptyMode.value ? 0 : 18),
        to: { name: 'base.home.overview', params: { view: 'tasks' } },
      },
      {
        name: 'Tickets',
        icon: 'email',
        counter: computed(() => isEmptyMode.value ? 0 : 22),
        to: { name: 'base.home.overview', params: { view: 'tickets' } },
      },
      {
        name: 'Deals',
        icon: 'tmi-sale',
        counter: computed(() => isEmptyMode.value ? 0 : 95),
        to: { name: 'base.home.overview', params: { view: 'deals' } },
      },
      {
        name: 'Contacts',
        icon: 'people',
        counter: computed(() => isEmptyMode.value ? 0 : '1.5K'),
        to: { name: 'base.home.contacts' },
      },
      {
        name: 'Organizations',
        icon: 'domain',
        counter: computed(() => isEmptyMode.value ? 0 : 29),
        to: { name: 'base.home.organizations' },
      },
    ])

    return {
      tabValue,
      tabs,
    }
  },
})
