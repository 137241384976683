import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_report_cards = _resolveComponent("report-cards")!

  return (_openBlock(), _createBlock(_component_report_cards, {
    cards: _ctx.overviewBlocks,
    "show-actions": "",
    "cards-in-row": _ctx.isXXlMax ? 2 : 4
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        label: "Replace",
        icon: "cached",
        "icon-mirror": "",
        onClick: _ctx.handleReplace
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["cards", "cards-in-row"]))
}