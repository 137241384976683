import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-overview" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_overview_filters = _resolveComponent("home-overview-filters")!
  const _component_home_overview_blocks = _resolveComponent("home-overview-blocks")!
  const _component_tm_expansion_section = _resolveComponent("tm-expansion-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_expansion_section, {
      label: "Overview",
      "label-bold": "",
      "no-padding": "",
      draggable: "",
      "border-on-collapse": "",
      expanded: ""
    }, _createSlots({
      default: _withCtx(() => [
        _createVNode(_component_home_overview_filters, { class: "lt-md mb-3" }),
        _createVNode(_component_home_overview_blocks)
      ]),
      _: 2
    }, [
      (!_ctx.isSmMax)
        ? {
            name: "header-right",
            fn: _withCtx(() => [
              _createVNode(_component_home_overview_filters)
            ])
          }
        : undefined
    ]), 1024)
  ]))
}