import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f985366a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-view-link" }
const _hoisted_2 = { class: "home-view-link__avatar" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "home-view-link__body" }
const _hoisted_5 = { class: "home-view-link__title" }
const _hoisted_6 = { class: "home-view-link__subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_emoji = _resolveComponent("tm-emoji")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 0,
            name: _ctx.icon,
            size: "large",
            class: "primary--text"
          }, null, 8, ["name"]))
        : (_ctx.emoji)
          ? (_openBlock(), _createBlock(_component_tm_emoji, {
              key: 1,
              name: _ctx.emoji,
              size: "large"
            }, null, 8, ["name"]))
          : (_ctx.url)
            ? (_openBlock(), _createElementBlock("img", {
                key: 2,
                src: _ctx.url
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.subtitle), 1)
    ])
  ]))
}