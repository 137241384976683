
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
})
