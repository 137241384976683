
import { defineComponent, ref } from 'vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'

export default defineComponent({
  components: {
    DetailsHero,
    TmAutoSizePanelItemButton,
  },
  setup() {
    const user = ref({
      name: 'John',
      avatarColor: 'orange',
    })

    return {
      user,
    }
  },
})
