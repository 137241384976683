import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home-pinned-views" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_home_view_link = _resolveComponent("home-view-link")!
  const _component_sidebar_empty = _resolveComponent("sidebar-empty")!
  const _component_tm_expansion_section = _resolveComponent("tm-expansion-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_expansion_section, {
      label: "Pinned views",
      expanded: ""
    }, {
      "header-right": _withCtx(() => [
        _createVNode(_component_tm_button, {
          "icon-only": "",
          size: "medium",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('selectEntity')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              name: "add",
              size: "small"
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (!_ctx.isEmptyMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pinnedViews, (item, i) => {
                return (_openBlock(), _createBlock(_component_home_view_link, _mergeProps({ key: i }, item), null, 16))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_sidebar_empty, {
              key: 1,
              icon: "tmi-pin",
              title: "No pinned views",
              text: "Add pinned views by clicking button above."
            }))
      ]),
      _: 1
    })
  ]))
}