
import { defineComponent, ref, computed } from 'vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import { assigneeOptions } from '@/definitions/_general/_data/optionsList'
import { dateCreated } from '@/definitions/shared/filters/filtersLib/data'
import FilterButton from '@/components/shared/filters/FilterButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import FilterCheckboxOption from '@/components/shared/filters/FilterCheckboxOption.vue'
import TmAssignee from '@/components/shared/TmAssignee.vue'

export default defineComponent({
  components: {
    FilterCheckboxOption,
    TmAssignee,
    TmDropdown,
    TmDropdownItem,
    FilterButton,
    DropdownFilterAssignee,
  },
  setup() {
    const workspaceItem = { fullName: 'Workspace', avatarIcon: 'domain' }
    const assignee = ref([
      workspaceItem,
    ])
    const isWorkspaceSelected = computed(() => !!assignee.value.length && assignee.value[0].fullName === workspaceItem.fullName)

    const dates = [
      'Last 90 days',
      'Last 30 days',
      'Last 7 days',
      'Yesterday',
      'Today',
    ]
    const handleWorkspaceClick = () => {
      if (isWorkspaceSelected.value) {
        assignee.value = []
      } else {
        assignee.value = [workspaceItem]
      }
    }

    return {
      handleWorkspaceClick,
      dates,
      isWorkspaceSelected,
      assignee,
      workspaceItem,
      assigneeOptions,
      dateCreated,
    }
  },
})
