import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-338838cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-white" }
const _hoisted_2 = {
  key: 0,
  class: "home-white__left"
}
const _hoisted_3 = {
  key: 1,
  class: "home-white__right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_hero = _resolveComponent("home-hero")!
  const _component_home_overview = _resolveComponent("home-overview")!
  const _component_home_assigned_to_me = _resolveComponent("home-assigned-to-me")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_home_pinned_views = _resolveComponent("home-pinned-views")!
  const _component_home_recently_viewed = _resolveComponent("home-recently-viewed")!
  const _component_page_block = _resolveComponent("page-block")!

  return (_openBlock(), _createBlock(_component_page_block, { size: "wide" }, {
    default: _withCtx(() => [
      _createVNode(_component_home_hero, {
        "current-tab": _ctx.currentTab,
        "onUpdate:current-tab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
        tabs: _ctx.isMdMax ? _ctx.tabs : [],
        "use-router-tabs": false,
        class: "mt-6 mb-4"
      }, null, 8, ["current-tab", "tabs"]),
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.isMdMax || _ctx.currentTab === 'dashboard')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_home_overview),
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component }) => [
                  _createVNode(_component_home_assigned_to_me, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isMdMax || _ctx.currentTab === 'views')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_home_pinned_views),
              _createVNode(_component_home_recently_viewed)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}