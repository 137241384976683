
import { defineComponent, ref } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'

export default defineComponent({
  components: {
    TmExpansion,
  },
  props: {
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    caption: {
      type: String,
    },
    labelBold: {
      type: Boolean,
    },
    borderOnCollapse: {
      type: Boolean,
    },
    noBorder: {
      type: Boolean,
    },
    expanded: {
      type: Boolean,
    },
    noPadding: {
      type: Boolean,
    },
    draggable: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const state = ref(props.expanded)

    return {
      state,
    }
  },
})
