import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-181c5ec4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-overview-filters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_assignee = _resolveComponent("tm-assignee")!
  const _component_filter_checkbox_option = _resolveComponent("filter-checkbox-option")!
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_filter_button = _resolveComponent("filter-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dropdown_filter_assignee, {
      modelValue: _ctx.assignee,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assignee) = $event)),
      options: _ctx.assigneeOptions,
      label: "Workspace",
      removable: false,
      "search-placeholder": "Search",
      "disable-menu": _ctx.isWorkspaceSelected,
      icon: "domain",
      "value-class": "emphasize--text",
      "update-model-value-on-click": ""
    }, {
      "before-filter": _withCtx(() => [
        _createVNode(_component_filter_checkbox_option, {
          selected: _ctx.isWorkspaceSelected,
          class: "mx-n4 mt-n2 mb-2 border-bottom",
          disable: !_ctx.isWorkspaceSelected && _ctx.assignee.length > 0,
          item: _ctx.workspaceItem,
          "onUpdate:modelValue": _ctx.handleWorkspaceClick
        }, {
          option: _withCtx(({ option }) => [
            _createVNode(_component_tm_assignee, {
              assignee: option,
              "custom-size": "32px",
              "avatar-icon": option.avatarIcon,
              "placeholder-custom-size": "16px",
              overflow: ""
            }, null, 8, ["assignee", "avatar-icon"])
          ]),
          _: 1
        }, 8, ["selected", "disable", "item", "onUpdate:modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue", "options", "disable-menu"]),
    _createVNode(_component_tm_dropdown, null, {
      menu: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dates, (item, i) => {
          return (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: i,
            label: item
          }, null, 8, ["label"]))
        }), 128))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_filter_button, {
          icon: "event",
          "btn-size": "large",
          removable: false,
          "value-class": "emphasize--text",
          value: "Last 30 days"
        })
      ]),
      _: 1
    })
  ]))
}