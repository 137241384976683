
import { defineComponent } from 'vue'
import TmExpansionSection from '@/components/shared/tmExpansion/TmExpansionSection.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { pinnedViews } from '@/definitions/home/data'
import HomeViewLink from '@/components/pages/home/sidebar/HomeViewLink.vue'
import { useModals } from '@/compositions/modals'
import SidebarEmpty from '@/components/shared/templates/SidebarEmpty.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    SidebarEmpty,
    HomeViewLink,
    TmExpansionSection,
    TmButton,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const { openModal } = useModals()

    return {
      isEmptyMode,
      pinnedViews,
      openModal,
    }
  },
})
