
import { defineComponent } from 'vue'
import { overviewBlocks, replaceInsightCategories } from '@/definitions/home/data'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import ReportCards from '@/components/shared/templates/reports/ReportCards.vue'
import { useModals } from '@/compositions/modals'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    ReportCards,
    TmDropdownItem,
  },
  setup() {
    const { isXXlMax } = useBreakpoints()

    const { openModal } = useModals()

    const handleReplace = () => {
      openModal('selectEntity', {
        title: 'Replace insight',
        categories: replaceInsightCategories,
        searchPlaceholder: 'Search insights',
        tabSquareIcon: true,
        tabBtnText: 'Replace',
      })
    }

    return {
      isXXlMax,
      overviewBlocks,
      handleReplace,
    }
  },
})
